import { useEffect, useState } from 'react';
import { Heading } from 'theme-ui';
import { PropellerImage } from './PropellerImage';
import { PropellerStyled } from './styled';

export const propellerSections = {
    operational: {
        name: 'Operational',
        description: null,
    },
    business_ownership: {
        name: 'Business & Ownership',
        description: null,
    },
    marketing_competition: {
        name: 'Marketing & Competition',
        description: null,
    },
    regulatory_legal: {
        name: 'Regulatory & Legal',
        description: null,
    },
};

export const Propeller = ({ type, ...props }) => {
    const [state, setState] = useState({});

    useEffect(() => {
        if (propellerSections[type]) {
            setState(propellerSections[type]);
        }
    }, [type]);

    return (
        <PropellerStyled {...props}>
            <Heading
                sx={{
                    color: '#19CEFF',
                    fontSize: '28px',
                    fontWeight: 400,
                    mb: '24px',
                }}
            >
                {state.name ?? ''}
            </Heading>
            <PropellerImage type={type} />
        </PropellerStyled>
    );
};
