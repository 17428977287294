import { createSlice, current } from '@reduxjs/toolkit';
import {
    getCurrentEntryAsync,
    getQuestionsAsync,
    getStepsAsync,
    startSubmissionAsync,
    storeAnswersAsync,
    finaliseEntryAsync,
    getIndustriesAsync,
    verifyAsync,
} from './appThunks';

const initialState = {
    activeStepIndex: 0,
    activeStep: null,
    activeFieldIndex: 0,
    canNavigate: false,
    headerHeight: null,
    submission: {
        status: 'idle',
        token: null,
        data: null,
        isComplete: false,
        isFinalised: false,
    },
    steps: {
        status: 'idle',
        data: null,
    },
    industries: {
        status: 'idle',
        data: null,
    },
    utmSource: null,
    ref: null,
    exitAssessment: {
        status: 'idle',
        data: null,
    },
};

const app = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setCanNavigate: (state, { payload }) => {
            return {
                ...state,
                canNavigate: payload,
            };
        },
        setComplete: (state, { payload }) => {
            return {
                ...state,
                canNavigate: payload ? true : state.canNavigate,
                submission: {
                    ...state.submission,
                    isComplete: payload,
                },
            };
        },
        setStep: (state, { payload }) => {
            return {
                ...state,
                activeStepIndex: payload,
                activeStep: state?.steps?.data?.[payload]
                    ? current(state.steps.data[payload])
                    : null,
            };
        },
        setFieldIndex: (state, { payload }) => {
            return {
                ...state,
                activeFieldIndex: payload,
            };
        },
        cancelSubmission: (state) => {
            localStorage.removeItem('authToken');
            return {
                ...state,
                submission: {
                    ...state.submission,
                    state: 'idle',
                    token: null,
                    isComplete: false,
                },
            };
        },
        setHeaderHeight: (state, { payload }) => {
            return {
                ...state,
                headerHeight: payload,
            };
        },
        setUtmSource: (state, { payload }) => {
            localStorage.setItem('utm_source', payload);
            return {
                ...state,
                utmSource: payload,
            };
        },
        setRefSource: (state, { payload }) => {
            localStorage.setItem('ref', payload);
            return {
                ...state,
                ref: payload,
            };
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(verifyAsync.pending, (state) => {
                state.exitAssessment.status = 'pending';
            })
            .addCase(verifyAsync.rejected, (state) => {
                state.exitAssessment.data = null;
                state.exitAssessment.status = 'rejected';
            })
            .addCase(verifyAsync.fulfilled, (state, { payload }) => {
                state.exitAssessment.data = payload?.data;
                state.exitAssessment.status = 'fulfilled';
            })
            .addCase(getStepsAsync.pending, (state) => {
                state.steps.status = 'pending';
            })
            .addCase(getStepsAsync.rejected, (state) => {
                state.steps.data = null;
                state.steps.status = 'rejected';
            })
            .addCase(getStepsAsync.fulfilled, (state, { payload }) => {
                state.steps.data = payload?.data;
                state.steps.status = 'fulfilled';
            })
            .addCase(getQuestionsAsync.pending, (state) => {
                state.steps.status = 'pending';
            })
            .addCase(getQuestionsAsync.rejected, (state) => {
                state.steps.data = null;
                state.steps.status = 'rejected';
            })
            .addCase(getQuestionsAsync.fulfilled, (state, { payload }) => {
                state.steps.data = payload?.data;
                state.steps.status = 'fulfilled';
            })
            .addCase(getIndustriesAsync.pending, (state) => {
                state.industries.status = 'pending';
            })
            .addCase(getIndustriesAsync.rejected, (state) => {
                state.industries.data = null;
                state.industries.status = 'rejected';
            })
            .addCase(
                getIndustriesAsync.fulfilled,
                (state, { payload }) => {
                    state.industries.data = payload?.data;
                    state.industries.status = 'fulfilled';
                }
            )
            .addCase(startSubmissionAsync.pending, (state) => {
                localStorage.removeItem('authToken');
                state.submission = { ...initialState.submission };
                state.activeStep =
                    state.steps.data?.[0] ?? initialState.activeStep;
                state.activeStepIndex = initialState.activeStepIndex;
                state.activeFieldIndex = initialState.activeFieldIndex;
                state.canNavigate = initialState.canNavigate;
            })
            .addCase(startSubmissionAsync.rejected, (state) => {
                state.submission.token = null;
                state.submission.status = 'rejected';
            })
            .addCase(
                startSubmissionAsync.fulfilled,
                (state, { payload }) => {
                    localStorage.setItem('authToken', payload);
                    state.submission.token = payload;
                    state.submission.status = 'fulfilled';
                }
            )
            .addCase(storeAnswersAsync.pending, (state) => {
                state.submission.status = 'pending';
            })
            .addCase(storeAnswersAsync.rejected, (state) => {
                state.submission.status = 'rejected';
            })
            .addCase(storeAnswersAsync.fulfilled, (state, { payload }) => {
                state.submission.data = payload?.data;
                state.submission.status = 'fulfilled';
            })
            .addCase(getCurrentEntryAsync.pending, (state) => {
                state.submission.status = 'pending';
            })
            .addCase(getCurrentEntryAsync.rejected, (state) => {
                state.submission.data = null;
                state.submission.status = 'rejected';
            })
            .addCase(
                getCurrentEntryAsync.fulfilled,
                (state, { payload }) => {
                    state.submission.data = payload?.data;
                    state.submission.status = 'fulfilled';

                    const steps = current(state?.steps);

                    if (payload?.data?.current_step === 'finalised') {
                        state.submission.isFinalised = true;
                    } else if (
                        steps?.data &&
                        payload?.data?.current_step
                    ) {
                        const currentIndex = steps.data.findIndex(
                            (i) => i.name === payload?.data?.current_step
                        );

                        state.activeStepIndex = currentIndex;
                        state.activeStep = steps.data[currentIndex];
                        state.activeFieldIndex = 0;
                    }

                    if (payload?.data?.can_finalise) {
                        state.submission.isComplete = true;
                        state.canNavigate = true;
                    }
                }
            )
            .addCase(finaliseEntryAsync.pending, (state) => {
                state.submission.status = 'pending';
            })
            .addCase(finaliseEntryAsync.rejected, (state) => {
                state.submission.status = 'rejected';
            })
            .addCase(
                finaliseEntryAsync.fulfilled,
                (state, { payload }) => {
                    state.submission.data = payload?.data;
                    state.submission.isFinalised = true;
                    state.submission.status = 'fulfilled';
                    localStorage.removeItem('utm_source');
                    localStorage.removeItem('ref');
                }
            ),
});

export const {
    setStep,
    setFieldIndex,
    setComplete,
    cancelSubmission,
    setCanNavigate,
    setHeaderHeight,
    setUtmSource,
    setRefSource,
} = app.actions;

export default app.reducer;
