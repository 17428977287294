import { finaliseEntryAsync } from 'features/appThunks';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { t } from 'i18next';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { Box, Button, Field as FieldUI, Heading, Text, Link, Flex } from 'theme-ui';
import { Icon } from '~/Common/Icon';
import { Loading } from '~/Common/Loading';
import { Field } from '~/Fields/FormikField';
import { GetReportFormStyled } from './styled';
import ReactMarkdown from 'react-markdown';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Full name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    company_name: Yup.string().required('Company name is required'),
    mobile: Yup.string().required('Mobile number is required'),
    isCheckboxChecked: Yup.boolean().oneOf([true], 'You must accept the terms')
  });

const GetReportFormUncoupled = ({
    submission,
    utmSource,
    utmRef,
    finaliseEntryAsync,
    exitAssessment,
    ...props
}) => {
    const [isLoading, setLoading] = useState(false);

    const handleSubmit = async (values, { errors, setErrors }) => {
        setLoading(true);
        await finaliseEntryAsync({...values, capitaliz_id: exitAssessment?.id})
            .unwrap()
            .catch((e) => {
                toast.error(e.data.message);
                setErrors(
                    Object.entries(e.data.errors).reduce((errors, i) => {
                        errors[i[0]] = i[1][0];
                        return errors;
                    }, {})
                );
            })
            .finally(() => setLoading(false));
    };

    return (
        <GetReportFormStyled {...props}>
            <Icon
                icon="email"
                sx={{
                    bg: '#19CEFF',
                    borderRadius: '50%',
                    width: '60px',
                    height: '60px',
                    position: 'absolute',
                    top: 0,
                    left: '50%',
                    marginLeft: '-30px',
                    marginTop: '-30px',
                }}
            />
            {isLoading ? (
                <Box sx={{ position: 'relative', py: '100px' }}>
                    <Loading />
                </Box>
            ) : submission.isFinalised ? (
                <Box mt={4}>
                    <Heading sx={{ mb: '13px', textAlign: 'center' }}>
                        {t(submission.data?.landing_page?.title ?? 'pages.results.fulfilled.form.heading')}
                    </Heading>

                    {submission.data?.landing_page?.description ? (
                        <Box sx={{
                            textAlign: 'center',
                            '> ul': {
                                pt: 3,
                                textAlign: 'left',
                                lineHeight: '1.5',
                                mx: 'auto',
                                listStyle: 'none',
                                pl: [3, 5],
                                pr: [3, 5]
                            },
                            '> ul li': {
                                position: 'relative',
                                pl: 4,
                                mb: 3
                            },
                            '> ul li:before': {
                                backgroundRepeat: 'no-repeat',
                                backgroundImage: `url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7L5 11L15 1' stroke='%2300AEEF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")`,
                                content: '""',
                                position: 'absolute',
                                left: 0,
                                top: '8px',
                                width: '20px',
                                height: '20px'
                            }
                        }}>
                            <ReactMarkdown>
                                {submission.data.landing_page?.description}
                            </ReactMarkdown>
                        </Box>
                    ) : (
                        <Text>
                            {t('pages.results.fulfilled.form.content')}
                        </Text>
                    )}

                    <Flex mt={4} sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: ['column', 'row']
                    }}>
                        {submission.data?.landing_page?.url ? (
                            <Button
                                as={Link}
                                target="_blank"
                                sx={{
                                    display: 'inline-flex',
                                    mb: 3,
                                    mr: [0, 2],
                                    width: ['100%', 'auto'],
                                    minWidth: ['100px', '300px']
                                }}
                                href={submission.data.landing_page?.url}
                            >
                                <Icon icon="phone" size="18px" />
                                {t(submission.data.landing_page?.button ?? 'pages.results.fulfilled.form.landing_button')}
                            </Button>
                        ) : null}
                    </Flex>

                    <Text mt={4} as="p" sx={{ fontSize: 0 }}>
                        {t(submission.data.landing_page?.sendoff ?? 'pages.results.fulfilled.form.sendoff')}
                    </Text>
                </Box>
            ) : null}
            {!submission.isFinalised && (
                <Box sx={{ display: isLoading ? 'none' : 'block' }}>
                    <Formik
                        initialValues={{
                            name: '',
                            email: exitAssessment?.email ?? '',
                            company_name: exitAssessment?.company ?? '',
                            mobile: '',
                            utm_source: utmSource,
                            ref: utmRef,
                            privacy: false
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, isValid }) => (
                            <>
                                <Heading sx={{ mb: '13px', fontWeight: 500 }}>
                                    {t(
                                        'pages.results.complete.form.heading'
                                    )}
                                </Heading>
                                <Text>
                                    {t(
                                        'pages.results.complete.form.content'
                                    )}
                                </Text>
                                <Box as={Form} sx={{ mt: '35px' }}>
                                    <Field
                                        label={t('fields.fullName.label')}
                                        placeholder={t(
                                            'fields.fullName.placeholder'
                                        )}
                                        variant="contactInput"
                                        name="name"
                                        as={FieldUI}
                                    />
                                    <Field
                                        label={t('fields.email.label')}
                                        placeholder={t(
                                            'fields.email.placeholder'
                                        )}
                                        variant="contactInput"
                                        name="email"
                                        as={FieldUI}
                                    />
                                    <Field
                                        label={t('fields.company.label')}
                                        placeholder={t(
                                            'fields.company.placeholder'
                                        )}
                                        variant="contactInput"
                                        name="company_name"
                                        as={FieldUI}
                                    />
                                    <Field
                                        label={t('fields.mobile.label')}
                                        placeholder={t(
                                            'fields.mobile.placeholder'
                                        )}
                                        variant="contactInput"
                                        name="mobile"
                                        as={FieldUI}
                                        type="number"
                                        onKeyDown={ (evt) => evt.key === '.' && evt.preventDefault() }
                                    />
                                    <Button
                                        type="submit"
                                        sx={{
                                            minWidth: ['unset', '345px'],
                                            width: ['100%', 'auto'],
                                            mt: '20px',
                                            ':disabled:hover': {
                                                backgroundColor: 'rgb(139, 139, 151)',
                                            },
                                        }}
                                        disabled={
                                            !isValid || !submission.data.can_finalise
                                        }
                                    >
                                        <Icon icon="results" />
                                        {t(
                                            'pages.results.complete.form.button'
                                        )}
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Formik>
                </Box>
            )}
        </GetReportFormStyled>
    );
};

export const GetReportForm = connect(
    ({ app }) => ({
        submission: app.submission,
        utmSource: app.utmSource ?? localStorage.getItem('utm_source'),
        utmRef: app.ref ?? localStorage.getItem('ref'),
        exitAssessment: app.exitAssessment.data,
    }),
    {
        finaliseEntryAsync,
    }
)(GetReportFormUncoupled);
