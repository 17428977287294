import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Text } from 'theme-ui';
import { Modal } from '~/Modals/Modal';

const RouterPromptUncoupled = ({
    isFinalised,
    when = true,
    cancelText = 'routerPrompt.buttons.cancel',
    okText = 'routerPrompt.buttons.confirm',
    onOK = () => true,
    onCancel = () => false,
}) => {
    const unblock = useRef(() => {});
    const history = useHistory();
    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState('');

    useEffect(() => {
        unblock.current = history.block((prompt) => {
            if (when) {
                setCurrentPath(prompt.pathname);
                setShowPrompt(true);
                return false;
            }
            return true;
        });
        return () => unblock.current();
    }, [history, when]);

    const handleOK = useCallback(async () => {
        if (onOK) {
            const canRoute = await Promise.resolve(onOK());
            if (canRoute) {
                unblock.current();
                history.push(currentPath);
            }
        }
    }, [currentPath, history, onOK]);

    const handleCancel = useCallback(async () => {
        if (onCancel) {
            const canRoute = await Promise.resolve(onCancel());
            if (canRoute) {
                unblock.current();
                history.push(currentPath);
            }
        }
        setShowPrompt(false);
    }, [currentPath, history, onCancel]);

    return showPrompt ? (
        <Modal
            title={
                isFinalised
                    ? t('routerPrompt.finalised.heading')
                    : t('routerPrompt.default.heading')
            }
            isOpen={showPrompt}
            onRequestClose={() => setShowPrompt(false)}
            onOk={handleCancel}
            okText={t(okText)}
            onCancel={handleOK}
            cancelText={t(cancelText)}
            closable={false}
        >
            <Text
                sx={{
                    color: 'darkBlue',
                    fontWeight: 600,
                    fontSize: '16px',
                }}
            >
                {isFinalised
                    ? t('routerPrompt.finalised.content')
                    : t('routerPrompt.default.content')}
            </Text>
        </Modal>
    ) : null;
};

export const RouterPrompt = connect(({ app }) => ({
    isFinalised: app?.submission?.isFinalised,
}))(RouterPromptUncoupled);
