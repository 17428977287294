export const theme = {
    breakpoints: ['960px', '1137px'],
    sizes: {
        container: '1046px',
    },
    fonts: {
        body: '"Open Sauce Two", sans-serif',
        heading: '"Open Sauce Two", sans-serif',
        input: '"Open Sauce Two", sans-serif',
    },
    colors: {
        primary: '#19CEFF',
        caption: '#8B8B97',
        white: '#fff',
        darkBlue: '#1E3552',
        darkerBlue: '#02A3DF',
        darkestBlue: '#0074DF',
        error: '#b33434',
        darkNavy: '#2C2960',
    },
    toast: {
        maxWidth: '800px',
    },
    buttons: {
        primary: {
            fontFamily: 'input',
            display: 'inline-flex',
            gridGap: '8px',
            padding: '18px 30px',
            minWidth: '184px',
            borderRadius: '6px',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            fontSize: '16px',
            textTransform: 'uppercase',
            transition: 'background 0.3s ease-in-out',
            color: 'darkNavy',
            fontWeight: '500',

            '&:disabled': {
                cursor: 'default',
                background: '#8B8B97',
            },
            '&:hover': {
                background: 'darkerBlue',
            },
            '&:focus': {
                background: 'darkerBlue',
                outlineOffset: '3px',
                outlineColor: 'darkestBlue',
                outlineStyle: 'solid',
                outlineWidth: '2px',
            },
        },
        primaryWhite: {
            fontFamily: 'input',
            display: 'inline-flex',
            gridGap: '8px',
            padding: '18px 30px',
            minWidth: '184px',
            borderRadius: '6px',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            fontSize: '16px',
            textTransform: 'uppercase',
            transition: 'background 0.3s ease-in-out',
            color: 'darkNavy',
            fontWeight: '500',

            '&:disabled': {
                cursor: 'default',
                background: '#8B8B97',
            },
            '&:hover': {
                background: 'darkNavy',
                color: 'white',
            },
            '&:focus': {
                background: 'darkestBlue',
                outlineOffset: '3px',
                outlineColor: 'darkestBlue',
                outlineStyle: 'solid',
                outlineWidth: '2px',
            },
        },
        secondary: {
            fontFamily: 'input',
            display: 'inline-flex',
            gridGap: '8px',
            padding: '18px 30px',
            minWidth: '184px',
            borderRadius: '6px',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            fontSize: '16px',
            textTransform: 'uppercase',
            background: 'white',
            color: 'caption',
            border: '2px solid #D9D9DE',
            transition: '0.3s ease-in-out',
            transitionProperty: 'background, color, border-color',
            fontWeight: '500',

            '&:disabled': {
                cursor: 'default',
                background: '#E5E7EB',
            },
            '&:hover': {
                outline: '2px solid #D9D9DE',
                color: 'black',
            },
            '&:focus': {
                background: 'darkestBlue',
                color: 'white',
                outlineOffset: '3px',
                outlineColor: 'darkestBlue',
                outlineStyle: 'solid',
                outlineWidth: '2px',
            },
        },
    },
    fontSizes: [12, 14, 16, 18, 24, 32, 48, 64, 72],
    fontWeights: {
        body: 400,
        heading: 700,
        bold: 700,
    },
    lineHeights: {
        body: 1.5,
        heading: 1.125,
    },
    text: {
        h1: {
            fontSize: [4, '40px'],
        },
        h2: {
            fontSize: [4, 5],
        },
        heading: {
            fontSize: '28px',
            lineHeight: '1.3',
            fontWeight: 600,
            color: 'white',
        },
        subheading: {
            fontSize: ['22px'],
            letterSpacing: '0.05em',
            color: '#19CEFF',
            textTransform: 'uppercase',
        },
        large: {
            fontSize: [6],
            fontWeight: 300,
        },
        boldheading: {
            display: 'block',
            color: '#19CEFF',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '1.4',
        },
    },
    forms: {
        label: { fontWeight: 300, mb: 2 },
        input: {
            fontFamily: 'input',
            border: '2px solid rgba(217, 217, 222, 0.5)',
            fontSize: ['18px'],
            padding: '24px',
            borderRadius: '18px',
            color: '#fff',

            '&:focus': {
                border: '2px solid #19CEFF',
                outline: 0,
            },
            '&:hover': {
                border: '2px solid #19CEFF',
                outline: 0,
            },
        },
        transparent: {
            fontSize: '16px',
            padding: '6px',
            border: 'none',
            borderRadius: '2px',
            fontWeight: 300,
        },
        contactInput: {
            fontFamily: 'input',
            padding: '15px 24px',
            mb: '24px',
            border: '1px solid #8B8B97',
            fontSize: '18px',
            fontWeight: 300,
            borderRadius: '10px',

            '&::placeholder': {
                color: '#fff',
                fontWeight: 300,
            },
        },
    },
    cards: {
        primary: {
            margin: '0 0 40px 0',
        },
    },
};
