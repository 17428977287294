import { Field as FormikField, useField } from 'formik';
import { Box } from 'theme-ui';
import { Error } from '~/Common/Error';
import { Icon } from '~/Common/Icon';

export const Field = ({ name, ...props }) => {
    const [, { error,touched }] = useField(name);
    const isError = error && touched
    return (
        <Box sx={{ position: 'relative' }}>
            <FormikField name={name} {...props} style={{border: isError ? "2px solid #FFA800" : ""}} />
            {isError &&  (
                <Error
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        margin: 0,
                        color: "#FFA800",
                        display:"flex",
                        alignItems:"center",
                        gap:"8px",
                    }}
                >
                    <Icon icon="warn" sx={{marginTop:"-2px"}}/>
                    {error}
                </Error>
            )}
        </Box>
    );
};
