import { Container } from 'theme-ui';
import { ContainerLayoutStyled } from './styled';

export const ContainerLayout = ({ children, ...props }) => {
    return (
        <ContainerLayoutStyled {...props}>
            <Container py={80} sx={{ maxWidth: '832px' }}>
                {children}
            </Container>
        </ContainerLayoutStyled>
    );
};
