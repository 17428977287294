import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import 'app/translations';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-W6HXX9R',
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// Turn off console logs for production
if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
}
