import { createAsyncThunk } from '@reduxjs/toolkit';
// import { store } from 'app/store';
import {
    getSteps,
    getQuestions,
    startSubmission,
    storeAnswers,
    getCurrentEntry,
    finaliseEntry,
    getIndustries,
    verify,
} from './appApi';

export const getCurrentEntryAsync = createAsyncThunk(
    'submission/get',
    async () => {
        try {
            const response = await getCurrentEntry();
            return response;
        } catch (e) {
            throw new Error(e);
        }
    }
);

export const getStepsAsync = createAsyncThunk('steps/get', async () => {
    try {
        const response = await getSteps();
        return response;
    } catch (e) {
        throw new Error(e);
    }
});

export const getQuestionsAsync = createAsyncThunk(
    'questions/get',
    async () => {
        try {
            const response = await getQuestions();
            return response;
        } catch (e) {
            throw new Error(e);
        }
    }
);

export const getIndustriesAsync = createAsyncThunk(
    'industries/get',
    async () => {
        try {
            const response = await getIndustries();
            return response;
        } catch (e) {
            throw new Error(e);
        }
    }
);

export const startSubmissionAsync = createAsyncThunk(
    'submission/start',
    async (recaptcha) => {
        try {
            const response = await startSubmission(recaptcha);
            return response;
        } catch (e) {
            throw new Error(e);
        }
    }
);

export const storeAnswersAsync = createAsyncThunk(
    'submission/store',
    async ({ step, answers }, { rejectWithValue }) => {
        try {
            const response = await storeAnswers(step, answers);
            return response;
        } catch ({ response }) {
            return rejectWithValue({
                data: response.data,
                status: response.status,
            });
        }
    }
);

export const finaliseEntryAsync = createAsyncThunk(
    'submission/finalise',
    async (data, { rejectWithValue }) => {
        try {
            const response = await finaliseEntry(data);
            return response;
        } catch ({ response }) {
            return rejectWithValue({
                data: response.data,
                status: response.status,
            });
        }
    }
);

export const verifyAsync = createAsyncThunk('submission/verify', async (route) => {
    try {
        const response = await verify(route);
        return response;
    } catch (e) {
        throw new Error(e);
    }
});
