import { axios } from 'utils/api';

export const getSteps = () => axios.get(`/steps`).then(({ data }) => data);

export const getIndustries = () =>
    axios.get(`/industries`).then(({ data }) => data);

export const getQuestions = () =>
    axios.get(`/questions`).then(({ data }) => data);

export const getCurrentEntry = () =>
    axios.get(`/entry`).then(({ data }) => data);

export const startSubmission = (recaptcha) =>
    axios
        .post(`/start`, { device_name: 'app', recaptcha })
        .then(({ data }) => data);

export const storeAnswers = (step, answers) =>
    axios.post(`/entry`, { step, answers }).then(({ data }) => data);

export const finaliseEntry = (values) =>
    axios.post(`/entry/finalise`, values).then(({ data }) => data);

export const verify = (route) => axios.create({
        baseURL: null,
        headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_CAPITALIZ_TOKEN}`
        }
    })
    .post(route)
    .then(({ data }) => data)
