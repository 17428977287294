import styled from 'styled-components';
import { Box } from 'theme-ui';

export const ImageListStyled = styled(Box)`
    display: flex;
    align-items: center;
    grid-gap: 46px;

    @media (max-width: ${({ theme }) => theme.breakpoints[0]}) {
        display: block;
    }
`;

export const ImageListChildren = styled.div`
    font-size: 20px;
    line-height: 28px;
`;

export const ListStyled = styled.ul`
    list-style: none;
    padding: 0;
    margin-top: 16px;

    ${({ isGrid }) =>
        isGrid
            ? `
        display: grid;

        li {
            padding-right: 32px;
        }

        @media(min-width: ${({ theme }) => theme.breakpoints[0]}) {
            grid-template-columns: repeat(2, 1fr);
        }
    `
            : ''}
`;

export const ListItemStyled = styled.li`
    padding: 6px 0;
    display: flex;
    grid-gap: 21px;
    align-items: center;
    font-weight: 500;
    line-height: 1.5;
`;
