import { ReactComponent as cross } from './cross.svg';
import { ReactComponent as dropdown } from './dropdown.svg';
import { ReactComponent as exit } from './exit.svg';
import { ReactComponent as back } from './back.svg';
import { ReactComponent as add } from './add.svg';
import { ReactComponent as comingSoon } from './coming-soon.svg';
import { ReactComponent as done } from './done.svg';
import { ReactComponent as tick } from './tick.svg';
import { ReactComponent as chart } from './chart.svg';
import { ReactComponent as email } from './email.svg';
import { ReactComponent as results } from './results.svg';
import { ReactComponent as userCircle } from './user-circle.svg';
import { ReactComponent as phone } from './phone.svg';
import { ReactComponent as warn } from './warn.svg';

const Icons = {
    cross,
    dropdown,
    exit,
    back,
    add,
    comingSoon,
    done,
    tick,
    chart,
    email,
    results,
    userCircle,
    phone,
    warn
};

export default Icons;
