import { Redirect, Route, Switch } from 'react-router-dom';
import { Steps } from 'pages/Steps';
import { Results } from 'pages/Results';
import { StepsLayout, ContainerLayout } from 'layouts';
import { Intro } from 'pages/Intro';

export const Routes = () => {
    return (
        <Switch>
            <Route path="/" exact>
                <ContainerLayout>
                    <Intro />
                </ContainerLayout>
            </Route>
            <Route path="*">
                <StepsLayout>
                    <Switch>
                        <Route path="/results" component={Results} exact />
                        <Route path="/submission" component={Steps} />
                        <Route path="*">
                            <Redirect to="/" />
                        </Route>
                    </Switch>
                </StepsLayout>
            </Route>
        </Switch>
    );
};
