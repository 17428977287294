import { Card } from '~/Common/Card';
import { ImageList } from '~/Common/ImageList';
import { Box, Container, Text } from 'theme-ui';
import { IndustryBenchmark } from '~/Results/IndustryBenchmark';
import { GetReportForm } from '~/Results/GetReportForm';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { setCanNavigate, setComplete } from 'features/appSlice';
import { InsightsReport } from 'assets/images';
import { LinkButton } from '~/Common/LinkButton';
import { t } from 'i18next';
import { appSettings } from 'app/settings';

const ResultsUncoupled = ({
    isComplete,
    isFinalised,
    canNavigate,
    setCanNavigate,
    setComplete,
}) => {
    const history = useHistory();

    useEffect(() => {
        if (!isComplete) {
            if (canNavigate) {
                history.push('/submission');
            } else {
                setCanNavigate(true);
            }
        } else {
            setCanNavigate(false);
        }
    }, [isComplete, canNavigate, history, setCanNavigate]);

    const handleBack = (e) => {
        e.preventDefault();
        setCanNavigate(false);
        setComplete(false);
    };

    if (!isComplete) {
        return null;
    }

    return (
        <>
            <Container>
                <Box sx={{ p: [3, 5], py: isFinalised ? 3 : '107px' }}>
                    {!isFinalised && (
                        <>
                            <LinkButton
                                icon="back"
                                sx={{
                                    position: 'absolute',
                                    left: '30px',
                                    top: '30px',
                                    '& svg': {
                                        transition:
                                            'transform 0.3s ease-in-out',
                                    },
                                    '&:hover svg': {
                                        transform: 'translateX(-6px)',
                                    },
                                }}
                                to="#"
                                onClick={handleBack}
                            >
                                {t('buttons.goBack')}
                            </LinkButton>
                            <Card
                                title={t('pages.results.complete.heading')}
                                icon="done"
                                iconSize={['42px', 'auto']}
                                titleSx={{
                                    whiteSpace: 'pre',
                                    fontWeight: 500,
                                }}
                            >
                                <ImageList
                                    image={InsightsReport}
                                    list={t(
                                        'pages.results.complete.list',
                                        {
                                            returnObjects: true,
                                        }
                                    )}
                                >
                                    {t(
                                        'pages.results.complete.listContent'
                                    )}
                                </ImageList>
                            </Card>
                            {appSettings.results.showIndustryBenchmark && (
                                <Card
                                    title={
                                        <>
                                            {t('Industry')}{' '}
                                            <u>{t('Benchmark Value')}</u>
                                        </>
                                    }
                                >
                                    <IndustryBenchmark mb={4} />
                                    <Text
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 300,
                                            lineHeight: '1.4',
                                        }}
                                    >
                                        {t(
                                            'pages.results.complete.disclaimer'
                                        )}
                                    </Text>
                                </Card>
                            )}
                        </>
                    )}
                    <GetReportForm />
                </Box>
            </Container>
        </>
    );
};

export const Results = connect(
    ({ app }) => ({
        isComplete: app.submission.isComplete,
        isFinalised: app.submission.isFinalised,
        canNavigate: app.canNavigate,
    }),
    { setCanNavigate, setComplete }
)(ResultsUncoupled);
