import styled from 'styled-components';
import { Box } from 'theme-ui';

export const PropellerStyled = styled(Box)`
    position: sticky;
    left: 0;
    top: 50px;
    padding: 24px;
    border-radius: 8px;
    background: linear-gradient(
        106.06deg,
        rgba(255, 255, 255, 0.1) 16.24%,
        rgba(255, 255, 255, 0) 80.37%
    );
    pointer-events: none;
    user-select: none;
    width: 279px;
    height: 321px;
    margin-bottom: -321px !important;

    @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
        left: 50px;
    }
`;

export const PropellerProgressStyled = styled(Box)`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #2c2960;
    z-index: 99999;
`;
