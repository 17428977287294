import ReactSelect from 'react-select';
import { Icon } from '~/Common/Icon';
import { Box } from 'theme-ui';

const components = {
    DropdownIndicator: () => {
        return (
            <Box px="35px">
                <Icon icon="dropdown" />
            </Box>
        );
    },
};

export const Select = ({
    value,
    options,
    menuShouldScrollIntoView,
    isSearchable = false,
    ...props
}) => {
    const customStyles = {
        option: (provided) => ({
            ...provided,
            padding: 20,
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: 23,
            fontSize: 18,
        }),
        control: (provided, state) => ({
            ...provided,
            border: state.isFocused 
                ? '2px solid #19CEFF'
                : props.isError
                ? '2px solid #FFA800'
                : '2px solid rgba(217, 217, 222, 0.5)',
            background: 'transparent',
            borderRadius: 16,
            boxShadow: 'none',
            "&:hover": {
                border: "2px solid #19CEFF"
              }
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#fff',
        }),
        menuList: (provided) => ({
            ...provided,
            color: '#000',
            height: "310px",
            minHeight: "310px"
        }),
        input: (provided) => ({
            ...provided,
            color: '#dedede',
        }),
    };

    const findOption = (name) => {
        if (!Array.isArray(options)) {
            return undefined;
        }
        return options.find((option) => option.value === name);
    };

    return (
        <ReactSelect
            components={components}
            styles={customStyles}
            options={options}
            menuShouldScrollIntoView={menuShouldScrollIntoView === true}
            value={typeof value !== 'object' ? findOption(value) : value}
            isSearchable={isSearchable}
            menuPlacement="bottom"
            {...props}
        />
    );
};
